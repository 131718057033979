<template>
  <div>
    <div v-if="!$apollo.loading">
      <b-row>
        <b-col lg="6">
          <b-card class="text-center" :title="'Filtre por tipo'">
            <b-row>
              <b-col md="2">
                <b-form-radio
                  v-model="grouping"
                  name="liberation-type"
                  class="m-1 pointer"
                  value="0"
                >
                  Jogo
                </b-form-radio>
              </b-col>
              <b-col md="2">
                <b-form-radio
                  v-model="grouping"
                  name="liberation-type"
                  class="m-1 pointer"
                  value="1"
                >
                  Usuario
                </b-form-radio>
              </b-col>
              
              <b-col md="2">
                <b-form-radio
                  v-model="grouping"
                  name="liberation-type"
                  class="m-1 pointer"
                  value="2"
                >
                  Provedor
                </b-form-radio>
              </b-col>
            </b-row>
            
            <b-row class="text-center mt-1">
              <b-col>
                <p>Bet</p>
                <span class="macro">{{ totalBet | brl }}</span>
              </b-col>
              <b-col>
                <p>Win</p>
                <span class="macro">{{ totalWin | brl }}</span>
              </b-col>
              <b-col>
                <p>GGR</p>
                <span class="macro">{{ totalGgr | brl }}</span>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col lg="6">
          <div class="row">
            <div class="col">
              <b-card title="Data inicial">
                <template>
                <b-form-datepicker
                  v-model="from"
                  locale="pt-br"
                  :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                />
                </template>
              </b-card>
            </div>
            <div class="col">
              <template>
                <b-card title="Data final">
                    <b-form-datepicker
                      v-model="to"
                      locale="pt-br"
                      :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit'}"
                    />
                </b-card>
              </template>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="ggrResult.length>0">
        <!-- <b-row class="w-100 mb-2">
            <b-col>Nome</b-col>
            <b-col>Depositos</b-col>
            <b-col>Saques</b-col>
            <b-col>Resultado</b-col>
          </b-row>
        <div v-for="transaction, idx in transactionsObj" class="w-100">
          <b-row>
            <b-col>{{ docs.filter(d=>d.user_id == idx)[0].value }}</b-col>
            <b-col>{{ transaction.deposits  | brl}}</b-col>
            <b-col>{{ transaction.withdrawals  | brl}}</b-col>
            <b-col>{{ transaction.deposits - transaction.withdrawals | brl}}</b-col>
          </b-row>
        </div>
         -->
        <b-table striped hover :items="ggrResult"></b-table>
      </b-row>
      <!-- <withdraws-list
        :orders="withdraw_orders.data"
        :context-actions="'admin'"
        @emitSidebar="openTransactionSidebar"
      /> -->
    </div>
    <div v-else>
      <logo-loader-animation />
    </div>
  </div>

</template>

<script>
import Datepicker from 'vuejs-datepicker'

import {
  BCard, BCol, BRow, BCardText, BLink, BFormRadio,BFormDatepicker, BTable
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { _ } from 'vue-underscore'
import LogoLoaderAnimation from '@/components/misc/LogoLoaderAnimation.vue'

export default {
  components: {
    BTable,
    BCard,
    BFormDatepicker,
    BCardText,
    BLink,
    underscore: _,
    LogoLoaderAnimation,
    BFormRadio,
    BCol,
    BRow,

  },
  data() {
    return {
      from: new Date(new Date().getTime() - (3 * 60 * 60 * 1000)).toISOString().split('T')[0],
      to: new Date(new Date().getTime() + (21 * 60 * 60 * 1000)).toISOString().split('T')[0],
      searchQuery: '',
      currentPage: 1,
      tableItems: [],
      transactionObj: null,
      transactionStatus: 0,
      fields: [
        { key: 'Index', sortable: true },
        { key: 'Bet', sortable: true },
        { key: 'Win', sortable: true },
        { key: 'Resultado', sortable: true },
      ],
      statusName: ['PENDING', 'CONFIRMED', 'CANCELED','PROCESSING'],
      ggrResult:null,
      usersToName:[],
      docs:false,
      totalBet:0,
      totalWin:0,
      totalGgr:0,
      grouping:'0',
      ggrResult:[],
      gamesArray: [{ "code":'AMAZONIAFANTASY5N6', "name":'Amazonia Fantasy'},
{ "code":'BINGO3N18', "name":'Bingo 3'},
{ "code":'CHAMPIONII9H5', "name":'Champion Bingo II'},
{ "code":'CLOVERSFANTASY7Q5', "name":'Clovers Fantasy'},
{ "code":'CODEX9R0', "name":'Codex'},
{ "code":'SCRATCHCARDMULTINIVEL6F4', "name":'El Tesoro Del Mono'},
{ "code":'SCRATCHCARDMULTINIVEL9A7', "name":'Goleada Milionaria'},
{ "code":'HALLOWEEN3D72', "name":'Halloween'},
{ "code":'LUCKYFRUITZ9U6', "name":'Lucky Fruits'},
{ "code":'MAKEAWISH8Q1', "name":'Make A Wish'},
{ "code":'MYSTICJOKER3Q2', "name":'Mystic Joker'},
{ "code":'PACHINKO2P90', "name":'Pachinko 2'},
{ "code":'PATAGONIA5T3', "name":'Patagonia Wilds'},
{ "code":'PIGGYCASH5I3', "name":'Piggy Cash'},
{ "code":'POPEYE6M4', "name":'Popeye Slots'},
{ "code":'QUAKE3K5', "name":'Quake'},
{ "code":'SPINISLAND2P4', "name":'Spin Island'},
{ "code":'SPINJONES9X9', "name":'Spin Jones'},
{ "code":'SUPERBOLA4O0', "name":'Super Bola'},
{ "code":'PHANTOM1T1', "name":'The Phantom'},
{ "code":'TURBONINETY3Q2', "name":'Turbo 90'},
{ "code":'WILDGEMS7I3', "name":'Wild Gems'},
{ "code":'9', "name":'Ace Bonus'},
{ "code":'1', "name":'Ace Mania'},
{ "code":'306', "name":'Beach'},
{ "code":'2', "name":'Bingo 6'},
{ "code":'24', "name":'Bingol'},
{ "code":'8', "name":'Bingolé'},
{ "code":'20', "name":'Bingolé HD'},
{ "code":'21', "name":'Bubble Mania'},
{ "code":'304', "name":'Dulce Mania'},
{ "code":'23', "name":'Gambeta'},
{ "code":'305', "name":'Goal Mania'},
{ "code":'15', "name":'Locomodin'},
{ "code":'22', "name":'Locomodin HD'},
{ "code":'4', "name":'Match Mania'},
{ "code":'14', "name":'New Triple'},
{ "code":'19', "name":'New Triple HD'},
{ "code":'309', "name":'Night Bingo'},
{ "code":'25', "name":'Olé Bingo'},
{ "code":'307', "name":'Roma'},
{ "code":'3', "name":'Triple Bonus'},
{ "code":'18', "name":'Triple Bonus HD'},
{ "code":'18', "name":'Triple Mobi'},
{ "code":'cg_astrowild', "name":'Astro Wild'},
{ "code":'cg_atomicolotto', "name":'Atomico Lotto'},
{ "code":'cg_bananabingo', "name":'Banana Bingo'},
{ "code":'cg_bananakeno', "name":'Banana Keno'},
{ "code":'cg_basketballpro', "name":'Basketball Pro'},
{ "code":'cg_beachtennis', "name":'Beach Tennis'},
{ "code":'cg_betinabingo', "name":'Betina Bingo'},
{ "code":'cg_billionllamainvegas', "name":'Billion Llama in Vegas'},
{ "code":'cg_billionllamascratchcard', "name":'Billion Llama Scratchcard'},
{ "code":'cg_bingobillionllama', "name":'Bingo Billion Llama'},
{ "code":'cg_bingobruxaria', "name":'Bingo Bruxaria'},
{ "code":'cg_bingocientistadoidao	', "name":'Bingo Cientista Doidão'},
{ "code":'cg_bingofadadafortuna', "name":'Bingo Fada da Fortuna'},
{ "code":'cg_bingogatinho', "name":'Bingo Gatinho'},
{ "code":'cg_bingogenio', "name":'Bingo Gênio'},
{ "code":'cg_bingohalloween', "name":'Bingo Halloween'},
{ "code":'cg_bingohortinha', "name":'Bingo Hortinha'},
{ "code":'cg_bingopirata	', "name":'Bingo Pirata'},
{ "code":'cg_sagaloca', "name":'Bingo Saga Loca'},
{ "code":'cg_senortaco', "name":'Bingo Senor Taco'},
{ "code":'cg_bingotornado', "name":'Bingo Tornado'},
{ "code":'cg_bingotrevodasorte', "name":'Bingo Trevo da Sorte'},
{ "code":'cg_bingolaco', "name":'Bingolaço'},
{ "code":'cg_bingolicia', "name":'Bingolícia'},
{ "code":'cg_botobingo', "name":'Boto Bingo'},
{ "code":'cg_carnavalscratchcard', "name":'Carnaval Scratchcard'},
{ "code":'cg_carnivalbeauties', "name":'Carnival Beauties'},
{ "code":'cg_catchafishbingo', "name":'Catch a Fish'},
{ "code":'cg_cavestreasures', "name":'Caves & Treasures'},
{ "code":'cg_cherrycherry', "name":'Cherry Cherry'},
{ "code":'cg_cherrycherryscratchcard', "name":'Cherry Cherry Scratchcard'},
{ "code":'cg_chinacharms', "name":'China Charms'},
{ "code":'cg_circusbingo', "name":'Circus Bingo'},
{ "code":'cg_crazylab', "name":'Crazy Lab'},
{ "code":'cg_cruiseoffortune', "name":'Cruise of Fortune'},
{ "code":'cg_crystalunicorn', "name":'Crystal Unicorn'},
{ "code":'cg_cuteycats', "name":'Cutey Cats'},
{ "code":'cg_dragonrising', "name":'Dragon Rising'},
{ "code":'cg_dressingroom', "name":'Dressing Room'},
{ "code":'cg_enchantedcash', "name":'Enchanted Cash'},
{ "code":'cg_feelthemusic', "name":'Feel the Music'},
{ "code":'cg_footballpro', "name":'Football Pro'},
{ "code":'cg_footballproscratchcard', "name":'Football Pro Scratchcard'},
{ "code":'cg_frightnight', "name":'Fright Night'},
{ "code":'cg_frozenfluffies', "name":'Frozen Fluffies'},
{ "code":'cg_goldfever', "name":'Gold Fever'},
{ "code":'cg_halloweenkeno', "name":'Halloween Keno'},
{ "code":'cg_halloweenlotto', "name":'Halloween Lotto'},
{ "code":'cg_halloweenpickem', "name":'Halloween Pickem'},
{ "code":'cg_halloweenscratchcard', "name":'Halloween Scratchcard'},
{ "code":'cg_hallowinner', "name":'Hallowinner'},
{ "code":'cg_heroesempire', "name":'Heroes Empire'},
{ "code":'cg_hiddenkingdom', "name":'Hidden Kingdom'},
{ "code":'cg_hungrychef', "name":'Hungry Chef'},
{ "code":'cg_hungrychefpickem', "name":'Hungry Chef Pickem'},
{ "code":'cg_hurricash', "name":'Hurricash'},
{ "code":'cg_junglekeno', "name":'Jungle Keno'},
{ "code":'is_lordsbalcony', "name":'Lords Balcony'},
{ "code":'cg_lostsaga', "name":'Lost Saga'},
{ "code":'cg_luckyexpress', "name":'Lucky Express'},
{ "code":'cg_luckyk', "name":'Lucky K'},
{ "code":'cg_madamefortune', "name":'Madame Fortune'},
{ "code":'cg_magicforest', "name":'Magic Forest'},
{ "code":'cg_magicalkeno', "name":'Magical Keno'},
{ "code":'cg_newfruit', "name":'New Fruit - RCT'},
{ "code":'cg_oceanrichies', "name":'Ocean Richies'},
{ "code":'cg_pickemfruits', "name":'Pick Em Fruits'},
{ "code":'cg_piratesoffortune', "name":'Pirates of Fortune'},
{ "code":'is_premierleaguecricket', "name":'Premier League Cricket'},
{ "code":'cg_princessoftheocean', "name":'Princess of the Ocean'},
{ "code":'cg_reisdoegito', "name":'Reis do Egito - RCT'},
{ "code":'cg_riodejaneiro', "name":'Rio de Janeiro - RCT'},
{ "code":'cg_samba', "name":'Samba - RCT'},
{ "code":'cg_sambario', "name":'Samba Rio'},
{ "code":'cg_senoritacalavera', "name":'Señorita Calavera'},
{ "code":'cg_talismane', "name":'Talismanes - RCT'},
{ "code":'cg_torchoffire', "name":'Torch of Fire'},
{ "code":'cg_trevodasortescratchcard', "name":'Trevo da Sorte Scratchcard'},
{ "code":'cg_vegasbaby', "name":'Vegas Baby'},
{ "code":'cg_vikingmadness', "name":'Viking Madness'},
{ "code":'cg_villagebrewery', "name":'Village Brewery'},
{ "code":'cg_wildfruit', "name":'Wild Fruit'},
{ "code":'cg_halloween', "name":'Halloween - RCT'},
{ "code":'1287', "name":'Ace Round'},
{ "code":'2344', "name":'Aeronauts'},
{ "code":'5849', "name":'Andar Nights'},
{ "code":'5547', "name":'Animal Quest'},
{ "code":'5789', "name":'Anubis Moon'},
{ "code":'2244', "name":'Atlantis'},
{ "code":'2916', "name":'Baccarat 777'},
{ "code":'583', "name":'Basketball'},
{ "code":'2374', "name":'Battle Tanks'},
{ "code":'2319', "name":'BlackJack Lucky Sevens'},
{ "code":'5774', "name":'Bloody Brilliant'},
{ "code":'5525', "name":'Bomb Squad'},
{ "code":'5776', "name":'Bonanza Wheel'},
{ "code":'5739', "name":'Book Of Keno'},
{ "code":'5493', "name":'Book of Rest'},
{ "code":'5479', "name":'Brutal Santa'},
{ "code":'2295', "name":'Candy Dreams'},
{ "code":'2931', "name":'Charming Queens'},
{ "code":'595', "name":'Chinese New Year'},
{ "code":'5748', "name":'Christmas Party'},
{ "code":'5815', "name":'Christmas Reach'},
{ "code":'5833', "name":'Christmas Reach Bonus Buy'},
{ "code":'610', "name":'Clash of Pirates'},
{ "code":'5489', "name":'Courier Sweeper'},
{ "code":'5585', "name":'Crown & Anchor'},
{ "code":'5799', "name":'Curse of Pharaoh'},
{ "code":'5805', "name":'Curse of the Pharaoh Bonus Buy'},
{ "code":'2379', "name":'Dolphins Treasure'},
{ "code":'5829', "name":'Dragons Tavern'},
{ "code":'5845', "name":'Dragons Tavern Bonus Buy'},
{ "code":'5452', "name":'Dungeon Immortal Evil'},
{ "code":'5089', "name":'E.T. Lost Socks'},
{ "code":'5173', "name":'E.T. Races'},
{ "code":'556', "name":'Egypt Gods'},
{ "code":'5758', "name":'Ellens Fortune'},
{ "code":'1290', "name":'Elven Princesses'},
{ "code":'2247', "name":'Epic Gladiators'},
{ "code":'5762', "name":'Epic Legends'},
{ "code":'5659', "name":'Exploding Fruits'},
{ "code":'5641', "name":'Fluffy Rangers'},
{ "code":'5760', "name":'Food Feast'},
{ "code":'1635', "name":'Football'},
{ "code":'5687', "name":'Football Manager'},
{ "code":'5721', "name":'Forest Dreams'},
{ "code":'5727', "name":'Forgotten Fable'},
{ "code":'2337', "name":'Four Aces'},
{ "code":'1614', "name":'Fruit Burst'},
{ "code":'5791', "name":'Fruit Disco'},
{ "code":'5741', "name":'Fruit Nova'},
{ "code":'5752', "name":'Fruit Super Nova'},
{ "code":'5797', "name":'Fruit Super Nova 100'},
{ "code":'5793', "name":'Fruit Super Nova 30'},
{ "code":'5775', "name":'Fruit Super Nova 40'},
{ "code":'5795', "name":'Fruit Super Nova 60'},
{ "code":'5823', "name":'Fruit Super Nova 80'},
{ "code":'2922', "name":'Fruits Land'},
{ "code":'5771', "name":'Gangster Night'},
{ "code":'5773', "name":'Gold of Sirens'},
{ "code":'5483', "name":'Maze: Desire for Power'},
{ "code":'2313', "name":'Heads & Tails'},
{ "code":'5344', "name":'High Striker'},
{ "code":'5487', "name":'Hungry Night'},
{ "code":'5740', "name":'Ice Mania'},
{ "code":'957', "name":'Indianas Quest'},
{ "code":'5551', "name":'Irish Reels'},
{ "code":'5695', "name":'Jelly Boom'},
{ "code":'655', "name":'Jewellery Store'},
{ "code":'5735', "name":'Jolly Treasures'},
{ "code":'631', "name":'Journey to the West'},
{ "code":'5485', "name":'Legend of Kaan'},
{ "code":'1704', "name":'Legend of Ra'},
{ "code":'5865', "name":'Lucky Card'},
{ "code":'5811', "name":'Lucky Crumbling'},
{ "code":'4384', "name":'Lucky Girls'},
{ "code":'559', "name":'Lucky Mahjong Box'},
{ "code":'5545', "name":'Mafia: Syndicate'},
{ "code":'5523', "name":'Magic Wheel'},
{ "code":'5753', "name":'Mehen'},
{ "code":'5653', "name":'Midnight Show'},
{ "code":'5573', "name":'Mine Field'},
{ "code":'2368', "name":'Monster Lab'},
{ "code":'2316', "name":'More or Less'},
{ "code":'5761', "name":'Mysteries of the East'},
{ "code":'2919', "name":'Mystery Planet'},
{ "code":'2241', "name":'Naughty Girls Cabaret'},
{ "code":'1956', "name":'Necromancer'},
{ "code":'5757', "name":'Neon Shapes'},
{ "code":'5737', "name":'Night Of The Living Tales'},
{ "code":'5398', "name":'Nuke World'},
{ "code":'2322', "name":'Oasis Poker Classic'},
{ "code":'5743', "name":'Pachin Girl'},
{ "code":'5863', "name":'Patricks Magic Field'},
{ "code":'5831', "name":'Penalty Series'},
{ "code":'5643', "name":'Penalty Shoot Out'},
{ "code":'5770', "name":'Plingoball'},
{ "code":'5356', "name":'Poker Teen Patti'},
{ "code":'2347', "name":'Prohibition'},
{ "code":'5717', "name":'Raccoon Tales'},
{ "code":'882', "name":'Red Cliff'},
{ "code":'2340', "name":'Red Queen'},
{ "code":'5401', "name":'Reign Of Dragons'},
{ "code":'5549', "name":'Rich Reels'},
{ "code":'5569', "name":'Rise of Horus'},
{ "code":'592', "name":'Robin Hood'},
{ "code":'2292', "name":'Robinson'},
{ "code":'4399', "name":'Robots: Energy Conflict'},
{ "code":'4393', "name":'Rock Paper Scissors'},
{ "code":'5593', "name":'Rocket Stars'},
{ "code":'2310', "name":'Roll The Dice'},
{ "code":'5767', "name":'Roll to Luck'},
{ "code":'5755', "name":'Runes Of Destiny'},
{ "code":'5781', "name":'Save the Hamster'},
{ "code":'5491', "name":'Scratch Match'},
{ "code":'5673', "name":'Sea of Spins'},
{ "code":'5527', "name":'Season Sisters'},
{ "code":'5778', "name":'Shadow Of Luxor'},
{ "code":'2259', "name":'Sindbad'},
{ "code":'5455', "name":'Sprinkle'},
{ "code":'5677', "name":'Surf Zone'},
{ "code":'5763', "name":'Sweet Sugar'},
{ "code":'4402', "name":'Syndicate'},
{ "code":'586', "name":'Talismans of Fortune'},
{ "code":'5746', "name":'Temple of Dead'},
{ "code":'5777', "name":'Temple of Dead Bonus Buy'},
{ "code":'5868', "name":'Temple of Thunder'},
{ "code":'5679', "name":'Texas Holdem Bonus'},
{ "code":'5553', "name":'Texas Holdem Poker'},
{ "code":'1707', "name":'The Emperors Tomb'},
{ "code":'2371', "name":'The Great Conflict'},
{ "code":'589', "name":'The Great Wall Treasure'},
{ "code":'637', "name":'The Legend of Shaolin'},
{ "code":'2331', "name":'The Slavs'},
{ "code":'2343', "name":'Thimbles'},
{ "code":'2262', "name":'Totem Island'},
{ "code":'5749', "name":'Treasure Mania'},
{ "code":'5742', "name":'Tree of Light'},
{ "code":'4408', "name":'Trip to the Future'},
{ "code":'5801', "name":'Triple Chili'},
{ "code":'5759', "name":'Unlimited Wishes'},
{ "code":'2385', "name":'USSR Grocery'},
{ "code":'2328', "name":'USSR Seventies'},
{ "code":'5738', "name":'Valley of Dreams'},
{ "code":'640', "name":'Vegas Nights'},
{ "code":'5587', "name":'Western Reels'},
{ "code":'5736', "name":'Wheel of Time'},
{ "code":'5751', "name":'Wild Bullets'},
{ "code":'5841', "name":'Wild Overlords'},
{ "code":'5750', "name":'Xmas Keno Cat'},
{ "code":'gr_bigbadbeasts', "name":'Big Bad Beasts'},
{ "code":'gr_bonusonly', "name":'Bonus Only'},
{ "code":'cg_paradisetrippiesbingo', "name":'Paradise Trippies Bingo'},
{ "code":'cg_bingoroyale', "name":'Bingo Royale'},
{ "code":'5887', "name":'Tree Of Light Bonus Buy'},
{ "code":'5897', "name":'Camino de Chili'},
{ "code":'5889', "name":'Money Minter'},
{ "code":'5891', "name":'FootBall Bet'},
{ "code":'5901', "name":'Wolf Hiding'},
{ "code":'cg_paradisetrippiesnonft', "name":'Paradise-Trippies Slot'},
{ "code":'5899', "name":'Magic Chests'},
{ "code":'CONDORITO9A7', "name":'Condorito'},
{ "code":'229', "name":'Mega Greatest Catch'},
{ "code":'BLACKJACK1X3', "name":'BlackJack'},
{ "code":'5903', "name":'Massive Luck'},
{ "code":'vswaysdogs', "name":'The Dog House Megaways'},
{ "code":'vs20starlight', "name":'Starlight Princess'},
{ "code":'vs20tweethouse', "name":'The Tweety House'},
{ "code":'vs40wildwest', "name":'Wild West Gold'},
{ "code":'vswayslions', "name":'5 Lions Megaways'},
{ "code":'vs20doghouse', "name":'The Dog House'},
{ "code":'vs20sbxmas', "name":'Sweet Bonanza Xmas'},
{ "code":'vs5aztecgems', "name":'Aztec Gems'},
{ "code":'vs25pandagold', "name":'Pandas Fortune'},
{ "code":'vswaysrhino', "name":'Great Rhino Megaways'},
{ "code":'vswayshammthor', "name":'Power of Thor Megaways'},
{ "code":'vs243lionsgold', "name":'5 Lions Gold'},
{ "code":'vs5joker', "name":'Jokers Jewels'},
{ "code":'vs20amuleteg', "name":'Fortune of Giza'},
{ "code":'vs243caishien', "name":'Caishens Cash'},
{ "code":'vs25hotfiesta', "name":'Hot Fiesta'},
{ "code":'vswayswildwest', "name":'Wild West Gold Megaways'},
{ "code":'vs12bbb', "name":'Bigger Bass Bonanza'},
{ "code":'vs10floatdrg', "name":'Floating Dragon'},
{ "code":'vswayssamurai', "name":'Rise of Samurai Megaways'},
{ "code":'vs9aztecgemsdx', "name":'Aztec Gems Deluxe'},
{ "code":'vs4096bufking', "name":'Buffalo King'},
{ "code":'vs25pandatemple', "name":'Panda Fortune 2'},
{ "code":'vs10chkchase', "name":'Chicken Chase'},
{ "code":'vs20mustanggld2', "name":'Clover Gold'},
{ "code":'vs20drtgold', "name":'Drill That Gold'},
{ "code":'vs10spiritadv', "name":'Spirit of Adventure'},
{ "code":'vs10firestrike2', "name":'Fire Strike 2'},
{ "code":'vs40cleoeye', "name":'Eye of Cleopatra'},
{ "code":'vs20hburnhs', "name":'Hot to Burn Hold and Spin'},
{ "code":'vs10bxmasbnza', "name":'Christmas Big Bass Bonanza'},
{ "code":'vswaysmadame', "name":'Madame Destiny Megaways'},
{ "code":'vs4096magician', "name":'Magicians Secrets'},
{ "code":'vs243queenie', "name":'Queenie'},
{ "code":'vs243discolady', "name":'Disco Lady'},
{ "code":'vs10tictac', "name":'Tic Tac Take'},
{ "code":'vs20rainbowg', "name":'Rainbow Gold'},
{ "code":'vs10snakeladd', "name":'Snakes and Ladders Megadice'},
{ "code":'vs50mightra', "name":'Might of Ra'},
{ "code":'vs10firestrike', "name":'Fire Strike'},
{ "code":'vs20xmascarol', "name":'Christmas Carol Megaways'},
{ "code":'vs7776aztec', "name":'Aztec Bonanza'},
{ "code":'vswaysxjuicy', "name":'Extra Juicy Megaways'},
{ "code":'vs20goldfever', "name":'Gems Bonanza'},
{ "code":'vs25scarabqueen', "name":'John Hunter and the Tomb of the Scarab Queen'},
{ "code":'vs10returndead', "name":'Return of the Dead'},
{ "code":'vswayslight', "name":'Lucky Lightning'},
{ "code":'vs20trsbox', "name":'Treasure Wild'},
{ "code":'vs25goldparty', "name":'Gold Party'},
{ "code":'vs243lions', "name":'5 Lions'},
{ "code":'vswaysyumyum', "name":'Yum Yum Powerways'},
{ "code":'vs25copsrobbers', "name":'Cash Patrol'},
{ "code":'vs5ultra', "name":'Ultra Hold and Spin'},
{ "code":'vs20rhinoluxe', "name":'Great Rhino Deluxe'},
{ "code":'vs15diamond', "name":'Diamond Strike'},
{ "code":'vs10bbbonanza', "name":'Big Bass Bonanza'},
{ "code":'vs20terrorv', "name":'Cash Elevator'},
{ "code":'vs243empcaishen', "name":'Emperor Caishen'},
{ "code":'vs10nudgeit', "name":'Rise of Giza PowerNudge'},
{ "code":'vs10amm', "name":'Amazing Money Machine'},
{ "code":'vs20bchprty', "name":'Wild Beach Party'},
{ "code":'vs10egyptcls', "name":'Ancient Egypt Classic'},
{ "code":'vs20santawonder', "name":'Santas Wonderland'},
{ "code":'vs20rhino', "name":'Great Rhino'},
{ "code":'vs20superx', "name":'Super X'},
{ "code":'vs40pirate', "name":'Pirate Gold'},
{ "code":'vs10fruity2', "name":'Extra Juicy'},
{ "code":'vs20fparty2', "name":'Fruit Party 2'},
{ "code":'vswayscryscav', "name":'Crystal Caverns Megaways'},
{ "code":'vs10cowgold', "name":'Cowboys Gold'},
{ "code":'vs7fire88', "name":'Fire 88'},
{ "code":'vs50juicyfr', "name":'Juicy Fruits'},
{ "code":'vs20ultim5', "name":'The Ultimate 5'},
{ "code":'vs10eyestorm', "name":'Eye of the Storm'},
{ "code":'vs243mwarrior', "name":'Monkey Warrior'},
{ "code":'vs25mustang', "name":'Mustang Gold'},
{ "code":'vswaysstrwild', "name":'Candy Stars'},
{ "code":'vs576treasures', "name":'Wild Wild Riches'},
{ "code":'vs5drhs', "name":'Dragon Hot Hold & Spin'},
{ "code":'vs20kraken', "name":'Release the Kraken'},
{ "code":'vs20phoenixf', "name":'Phoenix Forge'},
{ "code":'vs20rockvegas', "name":'Rock Vegas'},
{ "code":'vs1024temuj', "name":'Temujin Treasures'},
{ "code":'vs10runes', "name":'Gates of Valhalla'},
{ "code":'vs1dragon8', "name":'888 Dragons'},
{ "code":'vs25chilli', "name":'Chilli Heat'},
{ "code":'vs25kfruit', "name":'Aztec Blaze'},
{ "code":'vs10bbkir', "name":'Big Bass Bonanza - Keeping it Reel'},
{ "code":'vs12bbbxmas', "name":'Bigger Bass Blizzard - Christmas Catch'},
{ "code":'vs20trswild2', "name":'Black Bull'},
{ "code":'vs25bomb', "name":'Bomb Bonanza'},
{ "code":'vs10tut', "name":'John Hunter and the Book of Tut Respin'},
{ "code":'vswaysbook', "name":'Book of Golden Sands'},
{ "code":'vs243ckemp', "name":'Cheeky Emperor'},
{ "code":'vs40cosmiccash', "name":'Cosmic Cash'},
{ "code":'vs10crownfire', "name":'Crown of Fire'},
{ "code":'vs20underground', "name":'Down the Rails'},
{ "code":'vs100firehot', "name":'Fire Hot 100'},
{ "code":'vs20fh', "name":'Fire Hot 20'},
{ "code":'vs40firehot', "name":'Fire Hot 40'},
{ "code":'vs5firehot', "name":'Fire Hot 5'},
{ "code":'vswaysconcoll', "name":'Firebird Spirit - Connect & Collect'},
{ "code":'vswaysfltdrg', "name":'Floating Dragon Hold & Spin Megaways'},
{ "code":'vswaysfuryodin', "name":'Fury of Odin Megaways'},
{ "code":'vs20lcount', "name":'Gems of Serengeti'},
{ "code":'vs1024gmayhem', "name":'Gorilla Mayhem'},
{ "code":'vs20wolfie', "name":'Greedy Wolf'},
{ "code":'vs40mstrwild', "name":'Happy Hooves'},
{ "code":'vs20dugems', "name":'Hot Pepper'},
{ "code":'vs40hotburnx', "name":'Hot To Burn Extreme'},
{ "code":'vs20asgard', "name":'Kingdom of Asgard'},
{ "code":'vs243koipond', "name":'Koi Pond'},
{ "code":'vswayslofhero', "name":'Legend of Heroes'},
{ "code":'vs25tigeryear', "name":'Lucky New Year - Tiger Treasures'},
{ "code":'vs10mmm', "name":'Magic Money Maze'},
{ "code":'vs20muertos', "name":'Muertos Multiplier Megaways'},
{ "code":'vs20octobeer', "name":'Octobeer Fortunes'},
{ "code":'vswayspizza', "name":'PIZZA PIZZA PIZZA'},
{ "code":'vs20mtreasure', "name":'Pirate Golden Age'},
{ "code":'vs25rlbank', "name":'Reel Banks'},
{ "code":'vs20kraken2', "name":'Release the Kraken 2'},
{ "code":'vs20porbs', "name":'Santas Great Gifts'},
{ "code":'vs20sparta', "name":'Shield Of Sparta'},
{ "code":'vs100sh', "name":'Shining Hot 100'},
{ "code":'vs20sh', "name":'Shining Hot 20'},
{ "code":'vs40sh', "name":'Shining Hot 40'},
{ "code":'vs5sh', "name":'Shining Hot 5'},
{ "code":'vs10snakeeyes', "name":'Snakes & Ladders - Snake Eyes'},
{ "code":'vswaysfrywld', "name":'Spin & Score Megaways'},
{ "code":'vs5strh', "name":'Striking Hot 5'},
{ "code":'vs20sugarrush', "name":'Sugar Rush'},
{ "code":'vs20clspwrndg', "name":'Sweet Powernudge'},
{ "code":'vs20swordofares', "name":'Sword of Ares'},
{ "code":'vs20theights', "name":'Towering Fortunes'},
{ "code":'vswaysjkrdrop', "name":'Tropical Tiki'},
{ "code":'vs20mparty', "name":'Wild Hop & Drop'},
{ "code":'vs40spartaking', "name":'Spartan King'},
{ "code":'bjmb', "name":'American Blackjack'},
{ "code":'vs5ultrab', "name":'Ultra Burn'},
{ "code":'vs20emptybank', "name":'Empty the Bank'},
{ "code":'vs25btygold', "name":'Bounty Gold'},
{ "code":'vs20eightdragons', "name":'8 Dragons'},
{ "code":'vs25mmouse', "name":'Money Mouse'},
{ "code":'vs25rio', "name":'Heart of Rio'},
{ "code":'vs20bermuda', "name":'John Hunter and the Quest for Bermuda Riche'},
{ "code":'vs40bigjuan', "name":'Big Juan'},
{ "code":'vswayschilheat', "name":'Chilli Heat Megaways'},
{ "code":'vs243fortseren', "name":'Greek Gods'},
{ "code":'vs1024lionsd', "name":'5 Lions Dance'},
{ "code":'vs20chickdrop', "name":'Chicken Drop'},
{ "code":'vs1600drago', "name":'Drago - Jewels of Fortune'},
{ "code":'vs1fortunetree', "name":'Tree of Riches'},
{ "code":'cs5triple8gold', "name":'888 Gold'},
{ "code":'vs25pyramid', "name":'Pyramid King'},
{ "code":'vs20vegasmagic', "name":'Vegas Magic'},
{ "code":'bjma', "name":'Multihand Blackjack'},
{ "code":'vswaysbbb', "name":'Big Bass Bonanza Megaways'},
{ "code":'vs432congocash', "name":'Congo Cash'},
{ "code":'vs20colcashzone', "name":'Colossal Cash Zone'},
{ "code":'vs243dancingpar', "name":'Dance Party'},
{ "code":'vs40wanderw', "name":'Wild Depths'},
{ "code":'vs1masterjoker', "name":'Master Joker'},
{ "code":'vs243fortune', "name":'Caishens Gold'},
{ "code":'vs9piggybank', "name":'Piggy Bank Bills'},
{ "code":'vs25wolfgold', "name":'Wolf Gold'},
{ "code":'bca', "name":'Baccarat'},
{ "code":'vs20wildboost', "name":'Wild Booster'},
{ "code":'vswayswerewolf', "name":'Curse of the Werewolf Megaways'},
{ "code":'vs25wildspells', "name":'Wild Spells'},
{ "code":'vs5super7', "name":'Super 7s '},
{ "code":'vs10bookoftut', "name":'John Hunter and the Book of Tut'},
{ "code":'vs10luckcharm', "name":'Lucky, "name":Grace & Charm'},
{ "code":'vs40pirgold', "name":'Pirate Gold Deluxe'},
{ "code":'vs1tigers', "name":'Triple Tigers'},
{ "code":'vs10wildtut', "name":'Mysterious Egypt'},
{ "code":'vs25newyear', "name":'Lucky New Year'},
{ "code":'vswayswest', "name":'Mystic Chief'},
{ "code":'vs25peking', "name":'Peking Luck'},
{ "code":'vs1024dtiger', "name":'The Dragon Tiger'},
{ "code":'vs20smugcove', "name":'Smugglers Cove'},
{ "code":'vs25asgard', "name":'Asgard'},
{ "code":'vs25goldrush', "name":'Gold Rush'},
{ "code":'vs5spjoker', "name":'Super Joker'},
{ "code":'vs1024butterfly', "name":'Jade Butterfly'},
{ "code":'vs5hotburn', "name":'Hot to Burn'},
{ "code":'vs10mayangods', "name":'John Hunter And The Mayan Gods'},
{ "code":'vs10madame', "name":'Madame Destiny'},
{ "code":'vs20daydead', "name":'Day of Dead'},
{ "code":'vs25samurai', "name":'Rise of Samurai'},
{ "code":'vs25vegas', "name":'Vegas Nights'},
{ "code":'vswayshive', "name":'Star Bounty'},
{ "code":'vs25jokerking', "name":'Joker King'},
{ "code":'vs10threestar', "name":'Three Star Fortune'},
{ "code":'vs40voodoo', "name":'Voodoo Magic'},
{ "code":'vs25dragonkingdom', "name":'Dragon Kingdom'},
{ "code":'vs25kingdoms', "name":'3 Kingdoms - Battle of Red Cliffs'},
{ "code":'vs10goldfish', "name":'Fishin Reels'},
{ "code":'vs20hercpeg', "name":'Hercules and Pegasus'},
{ "code":'vs20egypttrs', "name":'Egyptian Fortunes'},
{ "code":'vs50safariking', "name":'Safari King'},
{ "code":'vs50pixie', "name":'Pixie Wings'},
{ "code":'vs20ekingrr', "name":'Emerald King Rainbow Road'},
{ "code":'vswaysbankbonz', "name":'Cash Bonanza'},
{ "code":'vs20honey', "name":'Honey Honey Honey'},
{ "code":'vs20santa', "name":'Santa'},
{ "code":'vs20wildpix', "name":'Wild Pixies'},
{ "code":'vs18mashang', "name":'Treasure Horse'},
{ "code":'vs9madmonkey', "name":'Monkey Madness'},
{ "code":'vs40madwheel', "name":'The Wild Machine'},
{ "code":'vs20leprexmas', "name":'Leprechaun Carol'},
{ "code":'vs9hotroll', "name":'Hot Chilli'},
{ "code":'vs20chicken', "name":'The Great Chicken Escape'},
{ "code":'vs10starpirate', "name":'Star Pirates Code'},
{ "code":'cs3w', "name":'Diamonds are Forever 3 Lines'},
{ "code":'vs9chen', "name":'Master Chens Fortune'},
{ "code":'vs20eking', "name":'Emerald King'},
{ "code":'vs50hercules', "name":'Hercules Son of Zeus'},
{ "code":'vs25davinci', "name":'Da Vincis Treasure'},
{ "code":'vs20magicpot', "name":'The Magic Cauldron'},
{ "code":'vs15fairytale', "name":'Fairytale Fortune'},
{ "code":'vs25journey', "name":'Journey to the West'},
{ "code":'vs20godiva', "name":'Lady Godiva'},
{ "code":'vs40frrainbow', "name":'Fruit Rainbow'},
{ "code":'vs5drmystery', "name":'Dragon Kingdom - Eyes of Fire'},
{ "code":'vs50chinesecharms', "name":'Lucky Dragons'},
{ "code":'cs3irishcharms', "name":'Irish Charms'},
{ "code":'vs3train', "name":'Gold Train'},
{ "code":'vs4096mystery', "name":'Mysterious'},
{ "code":'vs25safari', "name":'Hot Safari'},
{ "code":'vs7776secrets', "name":'John Hunter and the Aztec Treasure'},
{ "code":'vs25queenofgold', "name":'Queen of Gold'},
{ "code":'vs7pigs', "name":'7 Piggies'},
{ "code":'vs25sea', "name":'Great Reef'},
{ "code":'vs50aladdin', "name":'3 Genie Wishes'},
{ "code":'vs8magicjourn', "name":'Magic Journey'},
{ "code":'vs10vampwolf', "name":'Vampires vs Wolves'},
{ "code":'vs25walker', "name":'Wild Walker'},
{ "code":'vs50kingkong', "name":'Mighty Kong'},
{ "code":'vs40streetracer', "name":'Street Racer'},
{ "code":'vs5trdragons', "name":'Triple Dragons'},
{ "code":'vs20leprechaun', "name":'Leprechaun Song'},
{ "code":'vs20aladdinsorc', "name":'Aladdin and the Sorcerer'},
{ "code":'vs25gladiator', "name":'Wild Gladiator'},
{ "code":'vs10egypt', "name":'Ancient Egypt'},
{ "code":'vs20gorilla', "name":'Jungle Gorilla'},
{ "code":'vs25dwarves_new', "name":'Dwarven Gold Deluxe'},
{ "code":'vs7monkeys', "name":'7 Monkeys'},
{ "code":'1301', "name":'Live - Spaceman'},
{ "code":'701', "name":'Live - Mega Sic Bo'},
{ "code":'204', "name":'Live - Mega Roulette'},
{ "code":'901', "name":'Live - ONE Blackjack'},
{ "code":'1001', "name":'Live - Dragon Tiger'},
{ "code":'1024', "name":'Live - Andar Bahar'},
{ "code":'902', "name":'Live - ONE Blackjack 2 - Ruby'},
{ "code":'111', "name":'Live - Other Promos'},
{ "code":'vs25kingdomsnojp', "name":'3 Kingdoms - Battle of Red Cliffs'},
{ "code":'vs10txbigbass', "name":'Big Bass Splash'},
{ "code":'vs25bkofkngdm', "name":'Book Of Kingdoms'},
{ "code":'vs10bookfallen', "name":'Book of Fallen'},
{ "code":'vs10bookviking', "name":'Book of Vikings'},
{ "code":'vs20cleocatra', "name":'Cleocatra'},
{ "code":'scdiamond', "name":'Diamond Strike 100,000'},
{ "code":'vs20fruitparty', "name":'Fruit Party'},
{ "code":'vs20gobnudge', "name":'Goblin Heist Powernudge'},
{ "code":'scgoldrush', "name":'Gold Rush 250,000'},
{ "code":'vs20hockey', "name":'Hockey League'},
{ "code":'vs9hockey', "name":'Hockey League Wild Match'},
{ "code":'scsafari', "name":'Hot Safari 50,000'},
{ "code":'vs5littlegem', "name":'Little Gem Hold and Spin'},
{ "code":'114', "name":'Live - Asian Games'},
{ "code":'110', "name":'Live - D&W'},
{ "code":'108', "name":'Live - Dragon Tiger'},
{ "code":'105', "name":'Live - GAMESHOWS'},
{ "code":'103', "name":'Live - Blackjack Lobby'},
{ "code":'104', "name":'Live - Lobby Baccarat'},
{ "code":'107', "name":'Live - Sic Bo'},
{ "code":'109', "name":'Live - Sic Bo & Dragon Tiger'},
{ "code":'vs50northgard', "name":'North Guardians'},
{ "code":'vs10egrich', "name":'Queen of Gods'},
{ "code":'scqogai', "name":'Queen of Gold 100,000'},
{ "code":'scqog', "name":'Queen of Gold 100,000'},
{ "code":'vs25romeoandjuliet', "name":'Romeo and Juliet'},
{ "code":'vs117649starz', "name":'Starz Megaways'},
{ "code":'vs9catz', "name":'The Catfather'},
{ "code":'vs30catz', "name":'The Catfather Part II'},
{ "code":'vs20stickysymbol', "name":'The Great Stick-up'},
{ "code":'scwolfgold', "name":'Wolf Gold 1 Million'},
{ "code":'scwolfgoldai', "name":'Wolf Gold 1,000,000'},
{ "code":'vs25wolfjpt', "name":'Wolf Gold Power Jackpot'},
{ "code":'vswayszombcarn', "name":'Zombie Carnival'},
{ "code":'5911', "name":'Money Minter Bonus Buy'},
{ "code":'230', "name":'Old West'},
{ "code":'5867', "name":'Blessed Flame'},
{ "code":'5913', "name":'Redrose Sanctuary'},
{ "code":'5919', "name":'Rueda de Chile'},
{ "code":'5909', "name":'Hot Volcano Bonus Buy'},
{ "code":'5926', "name":'Collapsed Castle'},
{ "code":'5928', "name":'Football Scratch'},
{ "code":'5930', "name":'Wolf Hiding Bonus Buy'},
{ "code":'MARIACHI1Z8', "name":'El Mariachi'},
{ "code":'SCRATCHCARDMULTINIVEL9E5', "name":'Fortuna Mortal'},
{ "code":'cg_fruitverse', "name":'Fruitverse'},
{ "code":'gr_rememberremember', "name":'Remember Remember'},
{ "code":'cg_worldwildcup', "name":'World Wild Cup'},
{ "code":'cg_rainbowkeno', "name":'Rainbow Keno'},
{ "code":'44', "name":'Show Ball 3'},
{ "code":'43', "name":'Pachinko 3'},
{ "code":'5921', "name":'Inner Fire'},
{ "code":'49', "name":'Silverball'},
{ "code":'cg_redsheriff', "name":'Red Sheriff - RCT'},
{ "code":'vs20schristmas', "name":'Starlight Christmas'},
{ "code":'cg_kenogoal', "name":'Keno Goal'},
{ "code":'cg_footballlotto', "name":'Football Lotto'},
{ "code":'5957', "name":'Treasure-Snipes: Christmas Bonus Buy'},
{ "code":'ELDORADO2B4', "name":'El Dorado'},
{ "code":'vs20drgbless', "name":'Dragon Blessing'},
{ "code":'vswayswwhex', "name":'Wild Wild Bananas'},
{ "code":'vs20dhdice', "name":'The Dog House Dice Show'},
{ "code":'vs20bnnzdice', "name":'Sweet Bonanza Dice'},
{ "code":'vs20ltng', "name":'Pinup Girls'},
{ "code":'vs20mammoth', "name":'Mammoth Gold Megaways™'},
{ "code":'vs10fisheye', "name":'Fish Eye™'},
{ "code":'vs20superlanche', "name":'Monster Superlanche™'},
{ "code":'30maniaspinbonus', "name":'Baccarat'},
{ "code":'rockstarbingofullhd', "name":'Rock Star Bingo'},
{ "code":'eternalkingdomfbmmythiclink', "name":'Eternal Kingdom'},
{ "code":'blackjackvegasstrip', "name":'Blackjack Vegas Strip'},
{ "code":'sicbo', "name":'Sicbo'},
{ "code":'bonuspoker', "name":'Bonus Poker'},
{ "code":'30maniaspinbonus', "name":'30 Mania!™ Spin'},
{ "code":'aloha', "name":'Aloha'},
{ "code":'ancienttropicsbingo', "name":'Ancient Tropics'},
{ "code":'ancienttropicsfbmmythiclink', "name":'Ancient Tropics FBM Mythic Link'},
{ "code":'arcticblastbonus', "name":'Arctic Blast'},
{ "code":'bingoblastfullhd', "name":'Bingo Blast - FHD'},
{ "code":'bingogofullhd', "name":'Bingo-Go FHD'},
{ "code":'bingoliciousbonus', "name":'Bingolicious'},
{ "code":'blackjack', "name":'Blackjack'},
{ "code":'blackjackallfaceup', "name":'Blackjack All Face Up'},
{ "code":'blackjackvegasstripbonus', "name":'Blackjack Vegas Strip Bonus'},
{ "code":'blackjackvegasstrippro', "name":'Blackjack Vegas Strip Pro'},
{ "code":'cashoclockfullhd', "name":'Cash O’Clock - FHD'},
{ "code":'catchthegoldfullhd', "name":'Catch the Gold'},
{ "code":'catchthegoldfbmeasymoneylink', "name":'Catch the Gold FBM Easy Money Link'},
{ "code":'champion2', "name":'Champion II'},
{ "code":'championiifullhd', "name":'Champion II Deluxe'},
{ "code":'championiv', "name":'Champion IV'},
{ "code":'countrystyle2', "name":'Country Style'},
{ "code":'deuceswild', "name":'Deuces Wild FHD { "code":Video Poker}'},
{ "code":'fruitpicnic2', "name":'Fruit Picnic'},
{ "code":'goldenmetropolis', "name":'Golden Metropolis'},
{ "code":'goldenvoyagefbmmythiclink', "name":'Golden Voyage FBM Mythic Link'},
{ "code":'jacksorbetter', "name":'Jacks or better FHD { "code":Video Poker}'},
{ "code":'kachingbaby', "name":'Ka-ching Baby'},
{ "code":'kingdomgemsdiamond', "name":'Kingdom Gems Diamond'},
{ "code":'kingdomgemsfbmeasymoneylink', "name":'Kingdom Gems FBM Easy Money Link'},
{ "code":'magicchampionfullhd', "name":'Magic Champion - FHD'},
{ "code":'magictales', "name":'Magic Tales'},
{ "code":'mayabingo', "name":'Maya Bingo'},
{ "code":'multichampionbonus', "name":'Multi Champion Bonus'},
{ "code":'bingomultichampionbonusvip', "name":'Multi Champion Bonus VIP'},
{ "code":'multimega', "name":'Multimega'},
{ "code":'multimegafullhd', "name":'Multimega - FHD'},
{ "code":'naturesparadisefbmmythiclink', "name":'Natures Paradise FBM Mythic Link'},
{ "code":'neptunianriches', "name":'Neptunian Riches'},
{ "code":'neptunianricheseasylink', "name":'Neptunian Riches { "code":Easy Link}'},
{ "code":'pharaohslegacy', "name":'Pharaoh’s Legacy'},
{ "code":'plus3bonus', "name":'Plus 3 Bonus'},
{ "code":'plus3fullhd', "name":'Plus 3 - FHD'},
{ "code":'bingoplus3bonusvip', "name":'Plus 3 Bonus VIP'},
{ "code":'powerpicklotto', "name":'Power Pick Lotto'},
{ "code":'powerpicklottofullhd', "name":'Power Pick Lotto - FHD'},
{ "code":'racingowildfbmeasymoneylink', "name":'Racingo Wild FBM Easy Money Link'},
{ "code":'rainbowfalls', "name":'Rainbow Falls'},
{ "code":'romanadventure2', "name":'Roman Adventure'},
{ "code":'romanadventureways', "name":'Roman Adventure 243 Ways'},
{ "code":'romanadventurelines', "name":'Roman Adventure 50 Lines'},
{ "code":'royaltrucksways', "name":'Royal Trucks 243 Ways'},
{ "code":'royaltruckslines', "name":'Royal Trucks 50 Lines'},
{ "code":'rubingofullhd', "name":'Rubingo - FHD'},
{ "code":'rubingo20cardsfullhd', "name":'Rubingo - Reskin'},
{ "code":'sicbomacau', "name":'Sicbo Macau'},
{ "code":'thevaultheist', "name":'The Vault Heist'},
{ "code":'underwaterrichesbonus', "name":'Underwater Riches'},
{ "code":'underwaterrichesfbmeasymoneylink', "name":'Underwater Riches FBM Easy Money Link'},
{ "code":'vikingjourney', "name":'Viking Journey'},
{ "code":'virafesta', "name":'Viráfesta'},
{ "code":'vivamexicoslots', "name":'Viva Mexico'},
{ "code":'vivamexicobingofullhd', "name":'Viva Mexico - FHD { "code":Bingo}'},
{ "code":'xingfufortune', "name":'Xing Fú Fortune'},
{ "code":'zodiacwishes', "name":'Zodiac Wishes'},
{ "code":'vs25archer', "name":'Fire Archer™'},
{ "code":'vs12tropicana', "name":'Club Tropicana™'},
{ "code":'vs10powerlines', "name":'Peak Power™'},
{ "code":'vswayswwriches', "name":'Wild Wild Riches Megaways™'},
{ "code":'CARNAVALYALEGRIA4B4', "name":'Carnaval Alegria'},
{ "code":'5967', "name":'Book Of The Priestess'},
{ "code":'220', "name":'Camino de Chili Bonus Buy'},
{ "code":'5907', "name":'Candy Dreams: Sweet Planet Bonus Buy'},
{ "code":'5943', "name":'Collapsed Castle Bonus Buy'},
{ "code":'5947', "name":'Expanding Master'},
{ "code":'5787', "name":'Gold of Sirens Bonus Buy'},
{ "code":'5951', "name":'Hot Rio Nights'},
{ "code":'5963', "name":'Hot Rio Nights Bonus Buy'},
{ "code":'5902', "name":'Hot Volcano'},
{ "code":'5877', "name":'Juicy Gems'},
{ "code":'5879', "name":'Juicy Gems Bonus Buy'},
{ "code":'5905', "name":'Massive Luck Bonus Buy'},
{ "code":'5922', "name":'Mega Greatest Catch Bonus Buy'},
{ "code":'5935', "name":'Rueda De Chile Bonus Buy'},
{ "code":'5949', "name":'Sold it'},
{ "code":'5959', "name":'Sold it Bonus Buy'},
{ "code":'5871', "name":'The Greatest Catch'},
{ "code":'5873', "name":'The Greatest Catch Bonus Buy'},
{ "code":'vswaysmorient', "name":'Mystery of the Orient™'},
{ "code":'5941', "name":'Treasure-snipes: Christmas'},
{ "code":'5857', "name":'Wild Overlords Bonus Buy'},
{ "code":'5969', "name":'Treasure-Snipes'},
{ "code":'vs20doghousemh', "name":'The Dog House Multihold™'},
{ "code":'vswaysultrcoin', "name":'Cowboy Coins™'},
{ "code":'vs20mochimon', "name":'Mochimon™'},
{ "code":'5931', "name":'Perfect Fishing'},
{ "code":'BACCARAT6Y7', "name":'Baccarat'},
{ "code":'5981', "name":'Irish Weekend'},
{ "code":'vs20sknights', "name":'The Knight King™'},
{ "code":'cg_fishingseason', "name":'Fishing Season'},
{ "code":'EGYPTIANRICHES0Z2', "name":'Egyptian Riches Gold'},
{ "code":'5977', "name":'Book of the Priestess Bonus Buy'},
{ "code":'vs20goldclust', "name":'Rabbit Garden™'},
{ "code":'championtales', "name":'Champion Tales'},
{ "code":'vs10bbhas', "name":'Big Bass - Hold & Spinner™'},
{ "code":'vswaysredqueen', "name":'The Red Queen™'},
{ "code":'cg_coliseubingo', "name":'Coliseu Bingo'},
{ "code":'27002', "name":'Candy Clash'},
{ "code":'35001', "name":'Book of Wealth'},
{ "code":'16001', "name":'Cherry Bombs'},
{ "code":'17001', "name":'Coco Tiki'},
{ "code":'10003', "name":'Crystal Mine'},
{ "code":'14002', "name":'Zero Day'},
{ "code":'14001', "name":'Monster Thieves'},
{ "code":'18001', "name":'Portal Master'},
{ "code":'19001', "name":'Mancala Quest'},
{ "code":'20001', "name":'Spirit of the Lake'},
{ "code":'10010', "name":'Braindead'},
{ "code":'21001', "name":'The Twin Wins Mystery'},
{ "code":'23001', "name":'Fruit Factory'},
{ "code":'22001', "name":'Hot Fruits on Fire'},
{ "code":'24001', "name":'Reel Reel Hot'},
{ "code":'26001', "name":'Hot Fruits on Ice'},
{ "code":'28001', "name":'#BarsandBells'},
{ "code":'29001', "name":'Astro Jewels'},
{ "code":'27001', "name":'Seth vs Horus'},
{ "code":'30001', "name":'Neon Light Fruits'},
{ "code":'31001', "name":'Lucky Foxglove'},
{ "code":'32001', "name":'777 Vegas Showtime'},
{ "code":'33001', "name":'Seance: Mysterious Attic'},
{ "code":'22002', "name":'Superb Cup'},
{ "code":'34001', "name":'Fruit Collector'},
{ "code":'31002', "name":'Wild Velvet'},
{ "code":'36001', "name":'Fruityliner 40'},
{ "code":'22003', "name":'Fruityliner 5'},
{ "code":'39001', "name":'The Last Quack'},
{ "code":'38001', "name":'Buccaneer Royale'},
{ "code":'37001', "name":'Fruityliner 100'},
{ "code":'hgb', "name":'Halloween Groove Bingo'},
{ "code":'shb', "name":'Super Hot Bingo'},
{ "code":'sp', "name":'Super Pachinko'},
{ "code":'sbp', "name":'Show Ball+'},
{ "code":'zb', "name":'Zodiac'},
{ "code":'sf', "name":'Super Flex Bingo'},
{ "code":'cb', "name":'Candy'},
{ "code":'gb', "name":'Goal Bingo'},
{ "code":'pb', "name":'Pirates'},
{ "code":'farm', "name":'Farm'},
{ "code":'spp', "name":'Super Pachinko Plus'},
{ "code":'p3d', "name":'Pachinko 3D'},
{ "code":'c3d', "name":'Candy 3D'},
{ "code":'jbp', "name":'Jogo dos Bichos Praia'},
{ "code":'cg_damadafortunabingo', "name":'Dama da Fortuna Bingo'},
{ "code":'cg_classicroyals', "name":'Classic Royals'},
{ "code":'5979', "name":'Wonder Farm'},
{ "code":'5971', "name":'Super Bartender'},
{ "code":'5985', "name":'Wonder Farm Bonus Buy'},
{ "code":'5989', "name":'Irish Weekend Bonus Buy'},
{ "code":'spb-tb-aviator', "name":'Aviator'},
{ "code":'spb-tb-mines', "name":'Mines'},
{ "code":'spb-tb-dice', "name":'Dice'},
{ "code":'SICBO3V8', "name":'Sic Bo'},
{ "code":'CHOCOLATERIVER6P3', "name":'Chocolate Planet'},
{ "code":'op-slot-F777Fighter', "name":'F777 Fighter'},
{ "code":'op-slot-GoldenClover', "name":'Golden Clover'},
{ "code":'op-slot-jackpotterxmas', "name":'Jack Potter X-MAS'},
{ "code":'5987', "name":'Unlimited Treasures'},
{ "code":'5991', "name":'X-Demon'},
{ "code":'6001', "name":'Long Ball'},
{ "code":'5995', "name":'Unlimited Treasures Bonus Buy'},
{ "code":'5993', "name":'Neon Capital'},
{ "code":'vs10gizagods', "name":'Gods of Giza™'},
{ "code":'vs20hotzone', "name":'African Elephant™'},
{ "code":'vswaysrsm', "name":'Wild Celebrity Bus Megaways™'},
{ "code":'vswaysmonkey', "name":'3 Dancing Monkeys™'},
{ "code":'vs10jnmntzma', "name":'Jane Hunter and the Mask of Montezuma™'},
{ "code":'vs10kingofdth', "name":'Kingdom of The Dead™'},
{ "code":'bailaojunino', "name":'Bailão Junino'},
{ "code":'spb-tb-miniroulette', "name":'Mini Roulette'},
{ "code":'spb-tb-hilo', "name":'HiLo'},
{ "code":'spb-tb-plinko', "name":'Plinko'},
{ "code":'spb-tb-goal', "name":'goal'},
{ "code":'spb-tb-keno', "name":'Keno'},
{ "code":'spb-tb-hotline', "name":'HotLine'},
{ "code":'42001', "name":'Caishen Gold: Dragon Awakes'},
{ "code":'40002', "name":'Bounty Chasers'},
{ "code":'vs20excalibur', "name":'Excalibur Unleashed™'},
{ "code":'vs20stickywild', "name":'Wild Bison Charge™'},
{ "code":'vs25spotz', "name":'Knight Hot Spotz™'},
{ "code":'vswayseternity', "name":'Diamonds Of Egypt™'},
{ "code":'vs20lampinf', "name":'Lamp Of Infinity™'},
{ "code":'vs20jewelparty', "name":'Jewel Rush™'},
{ "code":'vs15godsofwar', "name":'Zeus vs Hades - Gods of War™'},
{ "code":'vs20clustwild', "name":'Sticky Bees™'},
{ "code":'vs9outlaw', "name":'Pirates Pub™'},
{ "code":'442', "name":'Live - Mega Baccarat'},
{ "code":'1601', "name":'Live - Snakes & Ladders Live'},
{ "code":'226', "name":'Live - Speed Auto-Roulette 1'},
{ "code":'240', "name":'Live - PowerUP Roulette'},
{ "code":'434', "name":'Live - Fortune 6 Baccarat'},
{ "code":'433', "name":'Live - Super 8 Baccarat'},
{ "code":'566', "name":'Live - Speed Blackjack 1 - Ruby'},
{ "code":'567', "name":'Live - Speed Blackjack 2 - Ruby'},
{ "code":'568', "name":'Live - Speed Blackjack 3 - Ruby'},
{ "code":'569', "name":'Live - Speed Blackjack 4 - Ruby'},
{ "code":'570', "name":'Live - Speed Blackjack 5 - Ruby'},
{ "code":'591', "name":'Live - Speed Blackjack 6 - Ruby'},
{ "code":'592', "name":'Live - Speed Blackjack 7 - Ruby'},
{ "code":'604', "name":'Live - Speed Blackjack 8 - Ruby'},
{ "code":'605', "name":'Live - Speed Blackjack 9 - Ruby'},
{ "code":'606', "name":'Live - Speed Blackjack 10 - Ruby'},
{ "code":'607', "name":'Live - Speed Blackjack 11 - Azure'},
{ "code":'608', "name":'Live - Speed Blackjack 12 - Azure'},
{ "code":'609', "name":'Live - Speed Blackjack 14 - Azure'},
{ "code":'612', "name":'Live - Speed Blackjack - 15 Ruby'},
{ "code":'613', "name":'Live - Speed Blackjack - 16 Ruby'},
{ "code":'614', "name":'Live - Speed Blackjack - 17 Ruby'},
{ "code":'615', "name":'Live - Speed Blackjack - 18 Ruby'},
{ "code":'546', "name":'Live - VIP Blackjack 1 - Ruby'},
{ "code":'547', "name":'Live - VIP Blackjack 2 - Ruby'},
{ "code":'548', "name":'Live - VIP Blackjack 3 - Ruby'},
{ "code":'549', "name":'Live - VIP Blackjack 4 - Ruby'},
{ "code":'550', "name":'Live - VIP Blackjack 5 - Ruby'},
{ "code":'617', "name":'Live - Blackjack 72 - Ruby'},
{ "code":'618', "name":'Live - Blackjack 73 - Ruby'},
{ "code":'616', "name":'Live - Blackjack 71 - Ruby'},
{ "code":'626', "name":'Live - Blackjack 70 - Ruby'},
{ "code":'619', "name":'Live - Blackjack 74 - Ruby'},
{ "code":'625', "name":'Live - Blackjack 69 - Ruby'},
{ "code":'624', "name":'Live - Blackjack 68 - Ruby'},
{ "code":'623', "name":'Live - Blackjack 67 - Ruby'},
{ "code":'622', "name":'Live - Blackjack 66 - Ruby'},
{ "code":'621', "name":'Live - Blackjack 65 - Ruby'},
{ "code":'620', "name":'Live - Blackjack 64 - Ruby'},
{ "code":'590', "name":'Live - Blackjack 63 - Azure'},
{ "code":'603', "name":'Live - Blackjack 62 - Azure'},
{ "code":'602', "name":'Live - Blackjack 61 - Azure'},
{ "code":'601', "name":'Live - Blackjack 60 - Azure'},
{ "code":'600', "name":'Live - Blackjack 59 - Azure'},
{ "code":'599', "name":'Live - Blackjack 58 - Azure'},
{ "code":'598', "name":'Live - Blackjack 57 - Azure'},
{ "code":'597', "name":'Live - Blackjack 55 - Ruby'},
{ "code":'596', "name":'Live - Blackjack 54 - Ruby'},
{ "code":'595', "name":'Live - Blackjack 53 - Ruby'},
{ "code":'593', "name":'Live - Blackjack 56 - Ruby'},
{ "code":'594', "name":'Live - Blackjack 52 - Ruby'},
{ "code":'554', "name":'Live - Blackjack 51 - Ruby'},
{ "code":'553', "name":'Live - Blackjack 50 - Ruby'},
{ "code":'552', "name":'Live - Blackjack 49 - Ruby'},
{ "code":'551', "name":'Live - Blackjack 48 - Ruby'},
{ "code":'561', "name":'Live - Blackjack 47 - Ruby'},
{ "code":'560', "name":'Live - Blackjack 46 - Ruby'},
{ "code":'559', "name":'Live - Blackjack 45 - Ruby'},
{ "code":'558', "name":'Live - Blackjack 44 - Ruby'},
{ "code":'557', "name":'Live - Blackjack 43 - Ruby'},
{ "code":'556', "name":'Live - Blackjack 42 - Ruby'},
{ "code":'555', "name":'Live - Blackjack 41 - Ruby'},
{ "code":'565', "name":'Live - Blackjack 40 - Ruby'},
{ "code":'564', "name":'Live - Blackjack 39 - Ruby'},
{ "code":'563', "name":'Live - Blackjack 38 - Ruby'},
{ "code":'562', "name":'Live - Blackjack 37 - Ruby'},
{ "code":'544', "name":'Live - Blackjack 36 - The Club'},
{ "code":'543', "name":'Live - Blackjack 35 - The Club'},
{ "code":'542', "name":'Live - Blackjack 34 - The Club'},
{ "code":'541', "name":'Live - Blackjack 33 - The Club'},
{ "code":'540', "name":'Live - Blackjack 32 - Azure { "code":Azure Studio II}'},
{ "code":'535', "name":'Live - Blackjack 31 - Azure { "code":Azure Studio II}'},
{ "code":'536', "name":'Live - Blackjack 30 - Azure { "code":Azure Studio II}'},
{ "code":'537', "name":'Live - Blackjack 29 - Azure { "code":Azure Studio II}'},
{ "code":'538', "name":'Live - Blackjack 28 - Azure { "code":Azure Studio II}'},
{ "code":'539', "name":'Live - Blackjack 27 - Azure { "code":Azure Studio II}'},
{ "code":'530', "name":'Live - Blackjack 26 - Azure { "code":Azure Studio II}'},
{ "code":'529', "name":'Live - Blackjack 25 - Azure { "code":Azure Studio II}'},
{ "code":'527', "name":'Live - Blackjack 23 - Azure { "code":Azure Studio II}'},
{ "code":'526', "name":'Live - Blackjack 22 - Azure { "code":Azure Studio II}'},
{ "code":'525', "name":'Live - Blackjack 21 - Azure { "code":Azure Studio II}'},
{ "code":'524', "name":'Live - Blackjack 20 - Azure { "code":Azure Studio II}'},
{ "code":'523', "name":'Live - Blackjack 19 - Azure { "code":Azure Studio II}'},
{ "code":'522', "name":'Live - Blackjack 18 - Azure { "code":Azure Studio II}'},
{ "code":'521', "name":'Live - Blackjack 17 - Azure { "code":Azure Studio II}'},
{ "code":'305', "name":'Live - Blackjack 16 { "code":Green Studio}'},
{ "code":'304', "name":'Live - Blackjack 15 { "code":Green Studio}'},
{ "code":'303', "name":'Live - Blackjack 14 { "code":Green Studio}'},
{ "code":'302', "name":'Live - Blackjack 12 { "code":Green Studio}'},
{ "code":'301', "name":'Live - Blackjack 11 { "code":Green Studio}'},
{ "code":'520', "name":'Live - Blackjack 8 - Azure { "code":Azure Studio I}'},
{ "code":'528', "name":'Live - Blackjack 24 - Azure { "code":Azure Studio II}'},
{ "code":'519', "name":'Live - Blackjack 2 - Azure { "code":Azure Studio I}'},
{ "code":'518', "name":'Live - Blackjack 5 - Azure { "code":Azure Studio I}'},
{ "code":'517', "name":'Live - Blackjack 10 - Azure { "code":Azure Studio I}'},
{ "code":'516', "name":'Live - Blackjack 9 - Azure { "code":Azure Studio I}'},
{ "code":'515', "name":'Live - Blackjack 1 - Azure { "code":Azure Studio I}'},
{ "code":'514', "name":'Live - Blackjack 4 - Azure { "code":Azure Studio I}'},
{ "code":'513', "name":'Live - Blackjack 7 - Azure { "code":Azure Studio I}'},
{ "code":'512', "name":'Live - Blackjack 6 - Azure { "code":Azure Studio I}'},
{ "code":'511', "name":'Live - Blackjack 3 - Azure { "code":Azure Studio I}'},
{ "code":'227', "name":'Live - Roulette 1 - Azure'},
{ "code":'201', "name":'Live - Roulette 2'},
{ "code":'401', "name":'Live - Baccarat 1'},
{ "code":'404', "name":'Live - Baccarat 2'},
{ "code":'422', "name":'Live - Baccarat 3'},
{ "code":'411', "name":'Live - Baccarat 5'},
{ "code":'413', "name":'Live - Baccarat 6'},
{ "code":'425', "name":'Live - Baccarat 7'},
{ "code":'426', "name":'Live - Baccarat 8'},
{ "code":'436', "name":'Live - No Comm Baccarat 1'},
{ "code":'203', "name":'Live - Speed Roulette 1'},
{ "code":'205', "name":'Live - Speed Roulette 2'},
{ "code":'225', "name":'Live - Auto-Roulette 1'},
{ "code":'402', "name":'Live - Speed Baccarat 1'},
{ "code":'403', "name":'Live - Speed Baccarat 2'},
{ "code":'412', "name":'Live - Speed Baccarat 3'},
{ "code":'414', "name":'Live - Speed Baccarat 5'},
{ "code":'415', "name":'Live - Speed Baccarat 6'},
{ "code":'431', "name":'Live - Speed Baccarat 7'},
{ "code":'432', "name":'Live - Speed Baccarat 8'},
{ "code":'430', "name":'Live - Speed Baccarat 9'},
{ "code":'428', "name":'Live - Speed Baccarat 10'},
{ "code":'424', "name":'Live - Speed Baccarat 11'},
{ "code":'421', "name":'Live - Speed Baccarat 12'},
{ "code":'438', "name":'Live - Speed Baccarat 13'},
{ "code":'405', "name":'Live - Speed Baccarat 14'},
{ "code":'427', "name":'Live - Speed Baccarat 15'},
{ "code":'435', "name":'Live - No Comm Speed Baccarat 1'},
{ "code":'439', "name":'Live - No Comm Speed Baccarat 2'},
{ "code":'5883', "name":'Pride Fight'},
{ "code":'sms-tb-balloon', "name":'Balloon'},
{ "code":'sms-tb-cappadocia', "name":'Cappadocia'},
{ "code":'sms-tb-spinx', "name":'SpinX'},
{ "code":'sms-tb-jetx3', "name":'JetX3'},
{ "code":'sms-tb-cricketx', "name":'CricketX'},
{ "code":'sms-tb-plinkox', "name":'PlinkoX'},
{ "code":'sms-tb-footballx', "name":'FootballX'},
{ "code":'vs10bbextreme', "name":'Big Bass Amazon Xtreme™'},
{ "code":'UNDERWATER1R7', "name":'Underwater'},
{ "code":'cg_caipirinhakeno', "name":'Caipirinha Keno'},
{ "code":'cg_piggyshowbingo', "name":'Piggy Show Bingo'},
{ "code":'dfe4cb6b0c1f41f79362b0b5b887f394', "name":'Infinite Blackjack'},
{ "code":'b69741d477294e0ea71b29b8be0c6d9d', "name":'Dream Catcher'},
{ "code":'31848e6ae9134b07b5333ff6e13f776a', "name":'Football Studio'},
{ "code":'d87ece57465b41da99a754c7ac551217', "name":'Deal or No Deal'},
{ "code":'b217b8f4041843418d7b2374f0343cac', "name":'Cash or Crash'},
{ "code":'9e8deff6c77d4b2aab80a7e95df9794b', "name":'Mega Ball'},
{ "code":'7b989ef8c2034591a6165ba48413187d', "name":'Monopoly Live'},
{ "code":'a9ec2afe49c94c2b82e2d9c0457412a1', "name":'Crazy Time'},
{ "code":'bc8f35d9919245479580f22bf59a022a', "name":'Caribbean Stud Poker'},
{ "code":'b691bcd63bfb4f8b9d9e983b59056369', "name":'Salon Privé Roulette'},
{ "code":'9e0f4852b0c64cbcbd4a71a99ff738bd', "name":'Speed Auto Roulette'},
{ "code":'e97aeefe271e40749fdf2c33c9767053', "name":'Double Ball Roulette'},
{ "code":'00f5a68c06204129878b338341782377', "name":'American Roulette'},
{ "code":'98610239fc834b619ca7524c72e7484d', "name":'Immersive Roulette'},
{ "code":'1bdd7868b894471e89bbe046183f932d', "name":'XXXtreme Lightning Roulette'},
{ "code":'1c48c39026cc44b7b6d4a143b21cc007', "name":'Roulette'},
{ "code":'764444e0c1fb466a921b8bdf1e6e7f98', "name":'Lightning Roulette'},
{ "code":'58cf3c2cb4ce41668f29ac23a3ca77a1', "name":'Power Blackjack'},
{ "code":'b5efa456973849ff8fe3f79f1d858b1b', "name":'Lightning Baccarat'},
{ "code":'19c5259b23df4693a6d1fc41f33b3987', "name":'Bac Bo'},
{ "code":'b178d80cd1e747788d6aaac6a3ba5871', "name":'Dragon Tiger'},
{ "code":'740cb62581164d469927fb7dcafa8fc4', "name":'Funky Time'},
{ "code":'bee6d3f9161f4ae38e05b3c94b0e4214', "name":'Captains Bounty'},
{ "code":'237159d9da63448ea806593da8a046af', "name":'Double Fortune'},
{ "code":'b0737a4c2e20481db130819a81879ddc', "name":'Dragon Hatch'},
{ "code":'4cdf6997a761474bb799d18d6e0997a7', "name":'Fortune Gods'},
{ "code":'314d78f4267940e5bdb12eb6d1acee94', "name":'Fortune Mouse'},
{ "code":'7ae6003630ef452683b4f4ca4c617cc7', "name":'Fortune Ox'},
{ "code":'97100d88814043b8b786e5eb7c9f7813', "name":'Fortune Tiger'},
{ "code":'6522bc763fb84137b9106449df4b0fc9', "name":'Ganesha Fortune'},
{ "code":'8d5db8bcf7a2417ab2eaef77b95ee9f7', "name":'Jewels of Prosperity'},
{ "code":'ff290dfc1bc54ec88d0eedb5d4a4c43c', "name":'Phoenix Rises'},
{ "code":'5a60a57d570d45ca96bf4ee198fbebe9', "name":'Piggy Gold'},
{ "code":'77068b5bc52d4e6a9cca15efefcc5d2a', "name":'Fortune Rabbit'},
{ "code":'5875', "name":'Goblin Run'},
{ "code":'6013', "name":'Penalty Shoot-Out Street'},
{ "code":'double', "name":'Double Roll'},
{ "code":'CARNIVAL1Z1', "name":'Carnival'},
{ "code":'cg_rocknreels', "name":'Rock n Reels'},
{ "code":'cg_bingopescaria', "name":'Bingo Pescaria'},
{ "code":'vs243nudge4gold', "name":'Hellvis Wild™'},
{ "code":'2bd5fe455fd041debd33d58c31cbf8bc', "name":'Roleta Brasileira'}]
    }
  },
  computed: {
    docState() {
      return this.searchQuery.length > 2
    },
  },
  created() {
    this.getNgr()
  },
  watch:{
    grouping:function(){
      this.getNgr();
    },
    from:function(){
      this.getNgr();
    },
    to:function(){
      this.getNgr();
    },
  },
  methods: {
    getNgr(){
      let url = '/';
      switch (this.grouping) {
        case '0':
          url = url + 'consolidated-ggr-game'
          break;
        case '2':
          url = url + 'consolidated-ggr-provider'
          break;
        case '1':
          url = url + 'consolidated-ggr-user'
          break;
      }
      // console.log(config)
      this.$gpas.post(url,{
        tenant_uid : config.TENANT_UID,
        from :this.from,
        to : this.to
      }).then((res)=>{
        this.totalBet =0
        this.totalWin =0
        this.totalGgr =0
        this.ggrResult = res.data;
        for (let i = 0; i < this.ggrResult.length; i++) {
          if(this.ggrResult[i].game_id && this.ggrResult[i].game_id.length > 0){
            console.log([0].name)
            let name = this.gamesArray.filter(g => g.code == this.ggrResult[i].game_id);
            if(name.length > 0){
              this.ggrResult[i].game_id = name[0].name
            }
          }
          if(this.ggrResult[i].name === null){
            this.ggrResult.splice(i, 1);

          }
          this.totalBet += this.ggrResult[i].bet;
          this.totalWin += this.ggrResult[i].win;
          this.totalGgr += this.ggrResult[i].ggr;
        }
      })

    }
  },
}
</script>

<style>

</style>
